.btn-style-1 {
    background-color: var(--color-blue);
    border-radius: 2em;
    border: 1px var(--color-blue) solid;
    color: white;
    padding: 0.5em 2em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    a {
        color: white;
    }
    &:hover {
        border-color: black;
        color: black;
        background-color: white;
        a {
            color: black;
        }
    }
}

.btn-style-2 {
    background-color: white;
    border-radius: 2em;
    border: 1px black solid;
    padding: 0.5em 2em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    a {
        color: black;
    }
    &:hover {
        border-color: var(--color-blue);
        color: white;
        background-color: var(--color-blue);
        a {
            color: white;
        }
    }
}

.app-box-shadow {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.app-list-item {
    &::before {
        display: inline-block;
        content: "";
        width: 0.6em;
        height: 0.6em;
        background-color: var(--color-blue);
        margin-right: 0.5em;
    }
}

.app-list-item-outside {
    position: relative;
    margin-left: 1em;
    &::before {
        position: absolute;
        top: 0.5em;
        left: -1em;
        display: inline-block;
        content: "";
        width: 0.6em;
        height: 0.6em;
        background-color: var(--color-blue);
        margin-right: 0.5em;
    }
}
