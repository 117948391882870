.main{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    a{
        width: clamp(25px, 8%, 64px);
        aspect-ratio: 1;
        &:not(:last-of-type){
            margin-right: clamp(10px, 8%, 50px);
        }
    }
}