.spacer{
	height: var(--header-height);
    @media screen and (max-width:480px) {
        height: 60px;
    }
}
.main {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    height: var(--header-height);
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    a {
        text-decoration: none;
        color: black;
        &:hover {
			color: var(--color-blue);
        }
    }
}

.logo {
    padding-left: 5vw;
    display: flex;
    align-items: center;
    font-weight: 700;

	span{
		font-weight: normal;
		font-size: 0.7em;
		text-transform: uppercase;
		align-self: flex-end;
		padding-left: 0.5em;
		@media screen and (max-width: 860px) {
			display: none;
		}
	}




    &:hover .btn-login {
        transform: rotateY(0);
    }
}

.btn-logo {
    font-size: 22px;
    height: 100%;
    display: flex;
    align-items: center;
}


.nav {
    padding-right: 5vw;
    ul {
        list-style-type: none;
        display: flex;
        gap: 20px;
    }
}

.mobile-main {
    position: fixed;
    top: 0;
    width: 100%;
    align-items: center;
    z-index: 100;
    height: 60px;
    background-color: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.5s cubic-bezier(0.64, 0.04, 0, 1);
    a {
        color: black;
    }
    .logo {
        font-size: 16px;
    }
}

.mobile-main-title {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.mobile-toggle {
    margin-right: 5vw;
    display: flex;
    align-items: center;
}

.mobile-toggle-bar {
    background-color: black;
    border-radius: 10px;
    height: 4px;
    width: 30px;
    transition: all 0.5s cubic-bezier(0.64, 0.04, 0, 1);
    &:before {
        display: block;
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: inherit;
        border-radius: 10px;
        top: calc(35% - 2px);
        transition: transform 0.5s cubic-bezier(0.64, 0.04, 0, 1);
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: inherit;
        border-radius: 10px;
        top: calc(65% - 2px);
        transition: transform 0.5s cubic-bezier(0.64, 0.04, 0, 1);
    }
}

.mobile-main.mobile-active {
    background-color: var(--color-home-card);
}

.mobile-main.mobile-active a {
    color: black;
}

.mobile-main.mobile-active .mobile-toggle-bar {
    background-color: transparent;
}

.mobile-main.mobile-active .mobile-toggle-bar::before {
    background-color: black;
    transform: rotate(45deg);
    top: calc(50% - 2px);
}

.mobile-active {
    .mobile-toggle-bar:after {
        background-color: black;
        transform: rotate(-45deg);
        top: calc(50% - 2px);
    }
	.mobile-main-body {
		opacity: 1;
		visibility: visible;
	}
}

.mobile-main-body {
    padding-top: 1em;
    height: 100vh;
    background-color: white;
    position: relative;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
	a {
		height: 3em;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 5vw;
		padding-right: 5vw;
		text-decoration: none;
		margin-top: 2px;
		margin-bottom: 2px;
		&:after {
			content: "";
			transform: rotate(-45deg);
			transform-origin: 50% 50%;
			height: 0.5em;
			width: 0.5em;
			border-right: 2px black solid;
			border-bottom: 2px black solid;
		}

		&:hover {
			box-shadow: 0 0 6px var(--color-home-card);
			// color: var(--color-home-card);
			color: var(--color-blue);
			background-color: var(--color-home-card-light);
			font-weight: bold;
			&:after {
				border-right: 3px var(--color-blue) solid;
				border-bottom: 3px var(--color-blue) solid;
			}
		}
	}
}