.main {
    display: grid;
    align-items: center;
    justify-content: center;
    min-height: clamp(585px, calc(100vh - var(--header-height)),1000000px);
    .background {
        width: 100vw;
        grid-row: 1/2;
        grid-column: 1/2;
        height: 100%;
        display: grid;
        grid-template-columns: 40% 60%;

        .left {
            background-color: var(--color-home-bg);
            height: 100%;
            width: 100%;
        }

        .right {
        }
        @media screen and (max-width: 860px) {
            grid-template-columns: 1fr;
            grid-template-rows: 300px 1fr;
            .left {
                background-color: white;
            }

            .right {
                background-color: var(--color-home-bg);
            }
        }
    }

    .card {
        margin: auto;
        grid-row: 1/2;
        grid-column: 1/2;
        display: grid;
        grid-template-columns: 375px 485px;
        @media screen and (max-width: 860px) {
            margin-top: 10rem;
            grid-template-columns: 1fr;
            width: 90%;
        }

        .left {
            text-align: center;
            display: grid;
            grid-template-rows: 1fr auto;
            background-color: white;
            .left-inner {
                padding-top: 5rem;
                background-color: var(--color-home-card);
                padding-bottom: 3rem;
                padding-left: 2rem;
                padding-right: 2rem;
                h1 {
                    font-size: 27px;
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                }
                .separator {
                    height: 2px;
                    width: 10%;
                    min-width: 50px;
                    margin: auto;
                    background-color: var(--color-blue);
                }
                img {
                    width: 60%;
                }

                p {
                    margin-top: 3rem;
                    text-transform: uppercase;
                    letter-spacing: 0.25em;
                }

                .buttons {
                    margin-top: 2rem;
                    display: flex;
                    justify-content: space-evenly;
                    a {
                        display: none;
                        font-size: 10px;
                        font-weight: bold;
                        width: 100px;
                        height: 30px;
                    }
                }
            }

            @media screen and (max-width: 480px) {
                .left-inner {
                    h1 {
                        margin-top: 1rem;
                        margin-bottom: 2rem;
                    }
                    p {
                        margin-top: 2rem;
                        font-size: 16px;
                    }
                    .buttons {
                        a {
                            display: inline-flex;
                        }
                    }
                }
            }
        }
        .right {
            padding-top: 3rem;
            padding-left: 5rem;
            padding-right: 5rem;
            padding-bottom: 2rem;
            text-align: left;

            h2 {
                margin-top: 1rem;
                font-size: 100px;
                line-height: 1em;
                @media screen and (max-width: 480px) {
                    margin-top: 5rem;
                    font-size: 55px;
                }
            }
            p:first-of-type {
                font-size: 25px;
                margin-top: 2rem;
                font-weight: bold;
            }
            a {
                margin-top: 4rem;
                margin-bottom: 4rem;
                font-size: 18px;
                width: 8em;
                padding-left: 0;
                font-weight: bold;
                padding-right: 0;
                &:first-of-type {
                    margin-right: 1em;
                }
            }
            p:last-of-type {
                font-size: 18px;
                line-height: 1.8em;
            }

            @media screen and (max-width: 480px) {
                padding: 10px;
                padding-bottom: 3rem;
                a {
                    display: none;
                }
                p:last-of-type {
                    margin-top: 1em;
                }
            }
        }
    }
}
