.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: clamp(1px,90%, 1400px);
    margin-left: auto;
    margin-right: auto;
    .left {
        font-size: 14px;
    }
    .right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
    }

    @media screen and (max-width: 860px) {
        padding: 2rem 1rem 5rem 1rem;

        display: grid;
        justify-content: center;
        .left {
            margin-top: 5rem;
        }
        .right {
            grid-row-start: 1;
            display: block;
        }
    }
}

.entry {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 16px;
    margin-top: 3rem;
    h3 {
        margin-bottom: 1rem;
    }
}
