.main {
    padding-top: 12rem;
    padding-bottom: 8rem;
    background-color: var(--color-home-bg);
    .body {
        max-width: var(--width-card);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        h1 {
            text-align: center;
            font-size: 35px;
        }
        h2 {
            margin-top: 8rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5rem;
        }
    }
}

.experience-card {
    margin-bottom: 4rem;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 6rem 6rem 6rem 6rem;
    .left {
        margin-bottom: 4rem;
        h4 {
            font-size: 18px;
            margin-bottom: 1rem;
        }
        h3 {
            color: var(--color-blue);
            font-size: 20px;
            margin-bottom: 1rem;
        }
        p {
            font-size: 18px;
        }
    }

    .right {
        p {
            line-height: 1.5em;
            margin-bottom: 0.8em;
            @media screen and (max-width: 480px) {
                font-size: 14px;
            }
        }
    }
}

.education-card {
    margin-bottom: 4rem;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 6rem 6rem 6rem 6rem;
    display: grid;
    .left {
        h4 {
            font-size: 18px;
            margin-bottom: 1rem;
        }
        h3 {
            color: var(--color-blue);
            font-size: 20px;
            margin-bottom: 1rem;
        }
        p {
            font-size: 18px;
        }
    }

    .right {
        align-self: flex-end;
        p {
            margin-top: 2rem;
            text-align: right;
            line-height: 1.5em;
            @media screen and (max-width: 480px) {
                font-size: 14px;
            }
        }
    }
}

:root {
    --width-card: 725px;
}
