.main {
    padding-top: 12rem;
    padding-bottom: 8rem;
    background-color: var(--color-home-bg);
    .body {
        max-width: var(--width-card);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        h1 {
            text-align: center;
            font-size: 35px;
        }
        h2 {
            margin-top: 8rem;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5rem;
        }
    }
}


.project-card {
    margin-bottom: 4rem;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 60% 40%;
    .left {
        margin-bottom: 4rem;
        h3 {
            color: var(--color-blue);
            font-size: 20px;
            margin-top: 4rem;
            margin-bottom: 4rem;
            border-left: 1rem solid var(--color-blue);
            height: 2em;
            display: flex;
            align-items: center;
            padding-right: 3rem;
            padding-left: 2rem;
        }
        p {
            padding-left: 3rem;
            padding-right: 3rem;
            line-height: 1.5em;
            margin-bottom: 4rem;
            @media screen and (max-width: 480px) {
                font-size: 14px;
            }
        }
    }

    .right {
        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    @media screen and (max-width: 860px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 200px;
        max-width: 360px;
    }
}
