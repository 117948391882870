html {
    font-size: 10px;
}

body {
    font-size: 1.6rem;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    cursor: pointer;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    display: inline-block;
    color: inherit;
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

p {
    font-size: 18px;
}

* {
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --header-height: 100px;
    --color-home-bg: rgb(230, 218, 206);
    --color-home-card: rgb(244, 236, 230);
    --color-home-card-light: rgb(255, 252, 250);
    --color-home-bg-darker: rgb(226, 201, 186);
    --color-blue: rgb(0, 80, 255);
    --color-azure: #409EFF;
    --color-azure-light: #F5F9FD;
}
